(function (wpm, $, undefined) {

	wpm.load_google_optimize_pixel = function () {

		try {
			if (wpmDataLayer?.pixels?.google?.optimize?.container_id) {

				wpmDataLayer.pixels.google.optimize.loaded = true

				wpm.loadScriptAndCacheIt("https://www.googleoptimize.com/optimize.js?id=" + wpmDataLayer.pixels.google.optimize.container_id)
				// .done(function (script, textStatus) {
				// 		console.log('Google Optimize loaded')
				// });
			}
		} catch (e) {
			console.error(e)
		}
	}

}(window.wpm = window.wpm || {}, jQuery));


(function () {

	jQuery(document).on("wpmLoadPixels", function () {

		if (wpm.canIFire("analytics", "google-optimize") && !wpmDataLayer?.pixels?.google?.optimize?.loaded) wpm.load_google_optimize_pixel()

	})

})()



