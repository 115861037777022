/**
 * After wpm is loaded
 * we first check if wpmDataLayer is loaded,
 * and as soon as it is, we load the pixels,
 * and as soon as the page load is complete,
 * we fire the wpmLoad event.
 */
wpm.wpmDataLayerExists()
	.then(function () {
		jQuery(document).trigger("wpmPreLoadPixels", {})
	})
	.then(function () {
		wpm.pageLoaded().then(function () {
			jQuery(document).trigger("wpmLoad", {})
		})
	})

