/**
 *  Load all free scripts first
 */

require("./wpm")

/**
 *  Load all free scripts
 */

require("./google")
require("./google-ga-ua")
require("./google-ga-4")
require("./google-ads")
require("./facebook")
require("./hotjar")
require("./google-optimize")

/**
 * Execute the wpm loader. It makes sure that the script flow gets exectuded correctly, no matter how JS "optimizers" shuffle the code.
 */

require("./wpm-loader")

// console.log('WooCommerce Pixel Manager free version')


