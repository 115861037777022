/**
 * Add functions for Google Analytics Universal
 * */

(function (wpm, $, undefined) {

	wpm.getGAUAOrderItems = function () {

		// "id"           : "34",
		// "name"         : "Hoodie",
		// "brand"        : "",
		// "category"     : "Hoodies",
		// "list_position": 1,
		// "price"        : 45,
		// "quantity"     : 1,
		// "variant"      : "Color: blue | Logo: yes"


		let orderItems    = [],
			list_position = 1

		for (const [key, item] of Object.entries(wpmDataLayer.order.items)) {

			let orderItem

			orderItem = {
				quantity     : item.quantity,
				price        : item.price,
				item_name    : item.name,
				currency     : wpmDataLayer.order.currency,
				item_category: wpmDataLayer.products[item.id].category.join("/"),
				list_position: list_position,
			}

			if (wpmDataLayer?.general?.variationsOutput && 0 !== item.variation_id) {

				orderItem.id         = String(wpmDataLayer.products[item.variation_id].dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type])
				orderItem.variant    = wpmDataLayer.products[item.variation_id].variant_name
				orderItem.item_brand = wpmDataLayer.products[item.variation_id].brand
				orderItems.push(orderItem)
			} else {

				orderItem.id         = String(wpmDataLayer.products[item.id].dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type])
				orderItem.item_brand = wpmDataLayer.products[item.id].brand
				orderItems.push(orderItem)
			}

			list_position++
		}

		return orderItems
	}

}(window.wpm = window.wpm || {}, jQuery))


/**
 * All event listeners
 * */


// view order received page event
jQuery(document).on("wpmOrderReceivedPage", function () {

	try {
		if (!wpmDataLayer?.pixels?.google?.analytics?.universal?.property_id) return
		if (wpmDataLayer?.pixels?.google?.analytics?.universal?.mp_active) return
		if (!wpm.googleConfigConditionsMet("analytics")) return

		wpm.gtagLoaded().then(function () {
		    gtag("event", "purchase", {
    			send_to       : [wpmDataLayer.pixels.google.analytics.universal.property_id],
    			transaction_id: wpmDataLayer.order.number,
    			affiliation   : wpmDataLayer.order.affiliation,
    			currency      : wpmDataLayer.order.currency,
    			value         : wpmDataLayer.order.value_regular,
    			discount      : wpmDataLayer.order.discount,
    			tax           : wpmDataLayer.order.tax,
    			shipping      : wpmDataLayer.order.shipping,
    			coupon        : wpmDataLayer.order.coupon,
    			items         : wpm.getGAUAOrderItems(),
    		})
		})

		// console.log(wpm.getGAUAOrderItems())
	} catch (e) {
		console.error(e)
	}
})
